import React, { useEffect } from 'react';
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './../contexts/auth';

const Login = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const { isAuthenticated, login } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      // Already logged in, redirect to home
      navigate('/');
    }

    // If we have a token in the URL, add to context
    else if (searchParams.has('token')) {
      const token = searchParams.get('token');
      
      if (token) login(token);

      // Remove token from URL
      setSearchParams({});

      // Redirect to home
      navigate('/');
    } 

    else {
      // If not, redirect to login
      window.location.href = `${process.env.REACT_APP_AUTH_URL}/login?redirect=${process.env.REACT_APP_URL}/login_callback`;
    }
    
  }, []);

  return <>
  </>;
}

export default Login;