import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from './../contexts/auth';

const Logout = () => {
  const { logout } = useAuth();

  useEffect(() => {
    logout();
  }, []);

  return <>
    <div id="content">

    </div>
  </>;
}

export default Logout;