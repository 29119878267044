import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

// Pages
import Login from './pages/Login';
import Logout from './pages/Logout';
import Homepage from './pages/Homepage';

// Components
// import Header from './components/Header';
// import Footer from './components/Footer';

// Contexts
import { AuthProvider, useAuth } from './contexts/auth';

// Styles
import { Theme } from './styles/Theme';
import { GlobalStyle } from './styles/GlobalStyle';

const AuthorisedRouter = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  
  const handleRedirect = () => {
    if (!isAuthenticated) {
      navigate('/login');
    }
  }

  useEffect(() => {
    handleRedirect();
  }, [isAuthenticated]);
  
  return <>
    {isAuthenticated ? <>
      {/* <Header />        */}
            
      <div id="content">
        <Routes>
          <Route path="/" element={<Homepage />} />
        </Routes>
      </div>

      {/* <Footer /> */}
    </> : <></>
    }
  </>;
}

const App = () => {
  return <>
    <ThemeProvider theme={Theme}>
      <GlobalStyle />
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/login_callback" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="*" element={ <AuthorisedRouter /> } />
          </Routes>        
        </AuthProvider>
      </Router>
    </ThemeProvider>
  </>;
}

export default App;
