import React, { useState, useEffect, useContext } from 'react';
import { Buffer } from 'buffer';


interface AuthContext {
  isAuthenticated: boolean;
  user: {
    email: string;
    status: string;
  }
  login: (token: string) => void;
  logout: () => void;
  request: (url: string, method: string, body?: any) => Promise<Response>;
}

const AuthContext = React.createContext<AuthContext>({} as AuthContext);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC = ({ children }) => {

  const [token, setToken] = useState('');
  const [user, setUser] = useState({} as { email: string, status: string });
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  
  const extractUser = (tkn: string) => {
    if (tkn !== '') {
      // Check if token has not expired
      let base64Data = tkn.split('.')[1];
      let userData: {email: string, status: string, exp: number, iat: number, scopes: string[]} = JSON.parse(
        Buffer.from(base64Data, 'base64').toString()
      );
      
      const now = new Date();
      const expires = new Date(userData.exp*1000);
      if (now > expires) {
        // Token expired
        localStorage.removeItem('userToken');
        setToken('');
        setUser({} as { email: string, status: string });
        setIsAuthenticated(false);
      } else {
        // Check scope
        if (!userData.scopes.includes('alumni-api')) {
          // Token does not have alumni-api scope
          localStorage.removeItem('userToken');
          setToken('');
          setUser({} as { email: string, status: string });
          setIsAuthenticated(false);
          window.location.href = '/login';

        } else {
          setToken(tkn);
          setUser({email: userData.email, status: userData.status});
          setIsAuthenticated(true);
        }
      }
    } else {
      setIsAuthenticated(false);
      setUser({} as { email: string, status: string });
      setToken('');
    }
  }

  const storeUser = (tkn: string) => {
    if (tkn !== '') {
      localStorage.setItem("userToken", tkn);
    }
  }

  const login = (tkn: string) => {
    extractUser(tkn);
  }

  const logout = () => {
    localStorage.removeItem('userToken');
    setToken('');
    setUser({} as { email: string, status: string });
    setIsAuthenticated(false);
    window.location.href = `${process.env.REACT_APP_URL}`;
  }

  const request = async (url: string, method: string, body?: any) => {
    const response = await fetch(`${process.env.REACT_APP_ALUMNI_API_URL}${url}`, {
      method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: body ? JSON.stringify(body) : undefined
    });
    return response;
  }

  useEffect(() => {
    storeUser(token);
  }, [token]);

  useEffect(() => {
    if (!isAuthenticated) {
      // Check if a token is stored in local storage
      const localToken = localStorage.getItem('userToken') || '';
      if (localToken !== '') extractUser(localToken);
    }
  }, []);

  return <>
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        login,
        logout,
        request,
      }}
    >
      {children} 
    </AuthContext.Provider>
  </>;
}

export default AuthContext;