import React, { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import Select, { SingleValue, Theme as SelectTheme } from 'react-select';

import { useAuth } from '../contexts/auth';

import { Theme } from '../styles/Theme';

import * as styles from './../styles/homepage';

const selectTheme = (theme: SelectTheme) => ({
  ...theme,
  borderRadius: 4,
  colors: {
    ...theme.colors,
    primary25: Theme.colors.primaryFaded,
    primary: Theme.colors.primary,
  },
});

interface UnverifiedUser {
  id: string,
  email: string,
  firstName: string,
  lastName: string,
  verified: boolean,
  verification_details: {
    matriculation_year: string,
    graduation_year: string,
  }
}

const Homepage = () => {
  const { request, user } = useAuth();

  const [unverifiedUsers, setUnverifiedUsers] = useState<UnverifiedUser[]>([]);


  const getData = async () => {
    const res = await request('/users/unverified', 'GET');
    const data = await res.json();
    
    setUnverifiedUsers(data.reverse());
  }

  useEffect(() => {
    getData();
  }, []);

  const verifyUser = async (id: string) => {
    const res = await request(`/user/${id}/verify`, 'POST');
    if (res.ok) {
      getData();
    }
  }

  return <>
    <styles.PageWrapper>
      <styles.Header id="header">
        <div className="info">
          <h1>St John's May Ball Alumni Verification</h1>
          <p>Logged in as <b>{user.email}</b></p>
        </div>
        <Link to={'/logout'}>Sign Out</Link>
      </styles.Header>
      <styles.Apply id="info">
        <h3>Verification site for St John's College May Ball</h3>
        <p>For each user listed below, please check the matriculation year and graduation year, and verify the user if they match records.</p>
        <p>Thanks from the May Ball ticketing team!</p>
      </styles.Apply>

      <styles.Users id="users">
        <h3>Unverified users:</h3>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Matriculation</th>
              <th>Graduation</th>
              <th>Verify</th>
            </tr>
          </thead>
          <tbody>
            {unverifiedUsers.map(user => <React.Fragment key={user.id}>
              <tr>
                <td>{user.firstName} {user.lastName}</td>
                <td>{user.email}</td>
                <td className="center">{user.verification_details.matriculation_year}</td>
                <td className="center">{user.verification_details.graduation_year}</td>
                <td><styles.SubtleButton onClick={() => verifyUser(user.id)}>Verify</styles.SubtleButton></td>
              </tr>
            </React.Fragment>)}
          </tbody>
        </table>
      </styles.Users>
      <styles.Footer id="footer"></styles.Footer>
    </styles.PageWrapper>
  </>;
}

export default Homepage;